import React, { useState } from 'react';
import { bool, arrayOf, number, shape } from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes, AFTER_PAY_ENABLE, ALOGOLIA_SEARCH_ATTRIBUTES, ALOGOLIA_SEARCH_COMPONENT, algoliaSortOptions } from '../../util/types';
import { ensureCurrentUser, ensureUser, makeFollowData, onGetAuthorReviews } from '../../util/data';
import { withViewport } from '../../util/uiHelpers';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Heading,
  H2,
  H4,
  Page,
  AvatarLarge,
  NamedLink,
  ListingCard,
  Reviews,
  ButtonTabNavHorizontal,
  LayoutSideNavigation,
  PaginationLinks,
  IconSpinner,
  LayoutSingleColumn,
  Button,
  IconProfileCard,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import ProfileSearchForm from './ProfileSearchForm/ProfileSearchForm';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createFollowUser, fetchFollowUsers } from '../../ducks/user.duck';
import AlgoliaSearchContent from '../../components/AlgoliaSearchContent/AlgoliaSearchContent';

import css from './ProfilePage.module.css';


const REACT_APP_MARKETPLACE_ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

const MAX_MOBILE_SCREEN_WIDTH = 768;

const showMoreLength = 500;

export const AsideContent = props => {
  const {
    user,
    displayName,
    isCurrentUser,
    reviews,
    totalItems,
    intl,
    onCreateFollowUser,
    currentUser,
    isFollowPending,
    isFollowSuccess,
    isFollowFailed,
    isFollowCreateRequest,
    isFollowCreateError,
    isMobileLayout,
    queryReviewsError
  } = props;

  const { storeName, welcomeMessage, businessCollection, pickupStore, enableAfterpay } = user?.attributes?.profile?.publicData || {};
  const isFollowPresent = Array.isArray(isFollowSuccess) && isFollowSuccess.filter((st) => user?.id?.uuid == st.followerId && currentUser?.id?.uuid == st.followingId);
  const { _id, isFollow, upComingMailSendAt=moment().unix(), lastListingPublished=1 } = isFollowPresent && isFollowPresent.length ? isFollowPresent[0] : {};

  const [showReviews, setShowReviews] = useState(true);

  // "pickupStore": "Yes",  
  const { reviewsOfProvider, reviewRating } = onGetAuthorReviews(reviews);
  const showAfterPay = enableAfterpay && enableAfterpay == AFTER_PAY_ENABLE ? true : false;

  return (
    <div className={css.asideContentMain}>
      <div className={css.topContent}>
        <div className={css.asideContent}>
          <div className={css.imageWrapper}>
            <AvatarLarge className={css.avatar} user={user} disableProfileLink />
            <div>
              <H2 as="h1" className={css.desktopHeading}>
                <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: storeName }} />
              </H2>
              <p className={css.totalProducts}>{totalItems} {intl.formatMessage({ id: 'ProfilePage.productsTotal' })} {businessCollection ? intl.formatMessage({ id: 'ProfilePage.marioOwned' }) : null}</p>
              <h5 className={css.details}>{welcomeMessage}</h5>
            </div>
          </div>
          <div className={css.contentRight}>
            <div className={css.shareButtons}>
              <Button
                inProgress={isFollowCreateRequest}
                onClick={() => {
                  if (isFollowPending || isFollowCreateRequest || isCurrentUser) {
                    return
                  }
                  const followData = makeFollowData(currentUser, user);
                  const payloadIs = _id ? { ...followData, id: _id, isFollow: isFollow == "2" ? "1" : "2", type: "update" , upComingMailSendAt, lastListingPublished } : {...followData, upComingMailSendAt, lastListingPublished };
                  return onCreateFollowUser(payloadIs);
                }} className={isFollow == "2" ? css.followedButton : css.primaryBtn}
              >
                {isFollow == "2" ? "Followed!" : "Follow"}
                {/* {intl.formatMessage({ id: 'ProfilePage.followButton' })} */}

              </Button>

              {/* <button className={css.secondaryBtn}>{intl.formatMessage({ id: 'ProfilePage.shareButton' })}</button> */}
              <div className={css.tooltip}>
                <button
                  className={css.shareButton}
                  onClick={(e) => {
                    e.preventDefault();
                    if (window && window.navigator && navigator.clipboard && navigator.clipboard.writeText) {
                      navigator.clipboard.writeText(`${REACT_APP_MARKETPLACE_ROOT_URL}/u/${user?.id?.uuid}`);
                      var tooltip = document.getElementById("myTooltip");
                      tooltip.innerHTML = "Copied"
                    }
                  }}
                  onMouseOut={(e) => {
                    e.preventDefault();
                    var tooltip = document.getElementById("myTooltip");
                    tooltip.innerHTML = "Copy to clipboard";
                  }}
                >
                  <span className={css.tooltiptext} id="myTooltip">Copy to clipboard</span>
                  {intl.formatMessage({ id: 'ProfilePage.shareButton' })}
                </button>
              </div>
              {/* <button className={css.secondaryBtn}>{intl.formatMessage({ id: 'ProfilePage.messageButton' })}</button> */}
            </div>
            <div className={css.reviewsWrapper}>
              <div>
                {Array(5).fill(",").map((st, i) => reviewRating > i ? <IconProfileCard type="star" /> : <IconProfileCard type="empty-star" key={i} />)}
              </div>
              {Array.isArray(reviewsOfProvider) && reviewsOfProvider.length} {intl.formatMessage({ id: 'ProfilePage.reviewTotal' })} </div>
            <div className={css.deliveryStatus}>
              <IconProfileCard type="tick-2" />
              {pickupStore && pickupStore == "Yes" ? intl.formatMessage({ id: 'ProfilePage.picupAndDelivery' }) : intl.formatMessage({ id: 'ProfilePage.Delivery' })}</div>
            {showAfterPay && <div className={css.deliveryStatus}>
              <IconProfileCard type="tick-2" />
              {intl.formatMessage({ id: 'ProfilePage.afterPayText' })}
            </div>}
          </div>
        </div>
        {/* review div */}

        {reviews.length ?
          <>
            {showReviews ?
              <div>
                {isMobileLayout ? (
                  <MobileReviews reviews={reviews} queryReviewsError={queryReviewsError} />
                ) : (
                  <DesktopReviews reviews={reviews} queryReviewsError={queryReviewsError} />
                )}
                <div className={css.upArrow} onClick={(e) => {
                  e.preventDefault();
                  setShowReviews(false);
                }}>
                  <IconProfileCard type="arrowup" />
                </div>
              </div>
              : <div className={css.downArrow}
                onClick={(e) => {
                  e.preventDefault();
                  setShowReviews(true);
                }}>
                <IconProfileCard type="arrowup" />
              </div>
            }
          </>
          : null}

      </div>
    </div>
  );
};

export const ReviewsErrorMaybe = props => {
  const { queryReviewsError } = props;
  return queryReviewsError ? (
    <p className={css.error}>
      <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
    </p>
  ) : null;
};

export const MobileReviews = props => {
  const { reviews, queryReviewsError } = props;
  const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);
  const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);
  return (
    <div className={css.mobileReviews}>
      <H4 as="h2" className={css.mobileReviewsTitle}>
        <FormattedMessage
          id="ProfilePage.reviewsFromMyCustomersTitle"
          values={{ count: reviewsOfProvider.length }}
        />
      </H4>
      <ReviewsErrorMaybe queryReviewsError={queryReviewsError} />
      <Reviews reviews={reviewsOfProvider} />
      {/* <H4 as="h2" className={css.mobileReviewsTitle}>
        <FormattedMessage
          id="ProfilePage.reviewsAsACustomerTitle"
          values={{ count: reviewsOfCustomer.length }}
        />
      </H4>
      <ReviewsErrorMaybe queryReviewsError={queryReviewsError} />
      <Reviews reviews={reviewsOfCustomer} /> */}
    </div>
  );
};

export const DesktopReviews = props => {
  const [showReviewsType, setShowReviewsType] = useState(REVIEW_TYPE_OF_PROVIDER);
  const { reviews, queryReviewsError } = props;
  const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);
  const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);
  const isReviewTypeProviderSelected = showReviewsType === REVIEW_TYPE_OF_PROVIDER;
  const isReviewTypeCustomerSelected = showReviewsType === REVIEW_TYPE_OF_CUSTOMER;
  const desktopReviewTabs = [
    {
      text: (
        <Heading as="h3" rootClassName={css.desktopReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsFromMyCustomersTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </Heading>
      ),
      selected: isReviewTypeProviderSelected,
      onClick: () => setShowReviewsType(REVIEW_TYPE_OF_PROVIDER),
    },
  ];

  return (
    <div className={css.desktopReviews}>
      <div className={css.desktopReviewsWrapper}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        <ReviewsErrorMaybe queryReviewsError={queryReviewsError} />

        {isReviewTypeProviderSelected ? (
          <Reviews reviews={reviewsOfProvider} />
        ) : (
          <Reviews reviews={reviewsOfCustomer} />
        )}
      </div>
    </div>
  );
};

export const MainContent = props => {
  const {
    userShowError,
    bio,
    displayName,
    listings,
    queryListingsError,
    reviews,
    queryReviewsError,
    viewport,
    user,
    isCurrentUser,
    history,
    routes,
    params,
    intl,
    pagination,
    searchParams,
    queryListingsRequest,
    onCreateFollowUser,
    currentUser,
    isFollowPending,
    isFollowSuccess,
    isFollowFailed,
    isFollowCreateRequest,
    isFollowCreateError,
  } = props;

  const { totalItems = 0 } = pagination || {};

  const hasListings = totalItems > 0;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const hasBio = !!bio;

  const listingsContainerClasses = classNames(css.listingsContainer, {
    [css.withBioMissingAbove]: !hasBio,
  });

  if (userShowError || queryListingsError) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingDataFailed" />
      </p>
    );
  }

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ProfilePage"
        pageSearchParams={searchParams}
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const { menuSelect } = ALOGOLIA_SEARCH_COMPONENT;

  return (
    <div>
      <AsideContent
        user={user}
        isCurrentUser={isCurrentUser}
        displayName={displayName}
        totalItems={totalItems}
        reviews={reviews}
        intl={intl}
        onCreateFollowUser={onCreateFollowUser}
        currentUser={currentUser}
        isFollowPending={isFollowPending}
        isFollowSuccess={isFollowSuccess}
        isFollowFailed={isFollowFailed}
        isFollowCreateRequest={isFollowCreateRequest}
        isFollowCreateError={isFollowCreateError}
        queryReviewsError={queryReviewsError}
        isMobileLayout={isMobileLayout}
      />

      {/* <div className={css.mainContent}>
        {hasBio ? <p className={css.bio}>{bio}</p> : null}
        <ProfileSearchForm
          history={history}
          routes={routes}
          params={params}
          intl={intl}
        />
        {queryListingsRequest ? <>
          <IconSpinner />
        </>
          : hasListings ? (
            <div className={listingsContainerClasses}>
              <ul className={css.listings}>
                {listings.map(l => (
                  <li className={css.listing} key={l.id.uuid}>
                    <ListingCard listing={l} showAuthorInfo={false} />
                  </li>
                ))}
              </ul>
              {paginationLinks}
            </div>
          ) : null}
      </div> */}

      <div className={css.mainContent}>
        <div className={css.gridContent}>
          {params?.id ? <AlgoliaSearchContent
            indexName={process.env.REACT_APP_ALGOLIA_LISTING_INDEX}
            isMobileLayout={isMobileLayout}
            currentUser={currentUser}
            configureProps={{
              filters: `state:"published" AND (userId:${params.id}) AND (publicData.totalStockAvailable > 0)`,
              ruleContexts: [],
              hitsPerPage: 12
            }}
            currentPage={"ProfilePage"}
            SortByFilters={algoliaSortOptions}
            allfilters={[
              {
                title: 'Collection',
                component: menuSelect,
                attribute: ALOGOLIA_SEARCH_ATTRIBUTES["collection"],
                searchablePlaceholder: 'Select A Collection: All',
                showMore: false,
                moreFilter: false
              },
            ]}
          />
            : null}
        </div>
      </div>
    </div>
  );
};

const ProfilePageComponent = props => {
  const config = useConfiguration();
  const routes = useRouteConfiguration();
  const history = useHistory();

  const { scrollingDisabled, currentUser, userShowError, user, intl, ...rest } = props;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const profileUser = ensureUser(user);
  const isCurrentUser =
    ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
  const { bio, displayName, publicData } = profileUser?.attributes?.profile || {};
  const { storeName = "" } = publicData || {};

  const schemaTitleVars = { name: storeName || displayName, marketplaceName: config.marketplaceName };
  const schemaTitle = intl.formatMessage({ id: 'ProfilePage.schemaTitle' }, schemaTitleVars);

  if (userShowError && userShowError.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ProfilePage',
        name: schemaTitle,
        mainEntity: JSON.stringify({
          '@type': 'ItemList',
          name: storeName || "RITA EDITED",
          itemListOrder: 'http://schema.org/ItemListOrderAscending',
          itemListElement: [{
            '@type': 'ListItem',
            position: 0,
            url: typeof window != "undefined" && window.location.url,
            name: storeName || "RITA EDITED",
          }
          ],
        })
      }}
    >
      <LayoutSingleColumn
        // sideNavClassName={css.aside}
        topbar={<TopbarContainer />}
        // sideNav={null}
        footer={<FooterContainer />}
        className={css.layoutSingleColumn}
      >
        <MainContent
          bio={bio}
          displayName={displayName}
          userShowError={userShowError}
          user={user}
          isCurrentUser={isCurrentUser}
          routes={routes}
          history={history}
          intl={intl}
          {...rest}
          currentUser={ensuredCurrentUser}
        />
      </LayoutSingleColumn>
    </Page>
  );
};

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

  const {
    currentUser,
    isFollowPending,
    isFollowSuccess,
    isFollowFailed,
    isFollowCreateRequest,
    isFollowCreateError,
  } = state.user;


  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
    pagination,
    searchParams,
    queryListingsRequest
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
    pagination,
    searchParams,
    queryListingsRequest,
    isFollowPending,
    isFollowSuccess,
    isFollowFailed,
    isFollowCreateRequest,
    isFollowCreateError,
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateFollowUser: (payload) => dispatch(createFollowUser(payload))
});

const ProfilePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

export default ProfilePage;
